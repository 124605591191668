:root{
  --margin:10px;
  --padding:10px;
  --primary-color:blueviolet;
  --border-radius:5px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rtl{
  direction: rtl;
}

.display-flex{
  display: flex;
}

.display-block{
  display: block;
}

.display-inline{
  display: inline;
}

.center{
  justify-items: center;
  justify-content: center;
  display: flex;
  
}

.pointer{
  cursor: pointer;
}

.text-black{
  color: black;
}

.color-primary{
  color: var(--primary-color);
}

.background-lightyellow{
  background-color: lightyellow;
}

.margin{
  margin: var(--margin);
}

.horizontal-margin{
  margin-left: var(--margin);
  margin-right: var(--margin);
}

.padding{
  padding: var(--padding);
}

.circular-border{
  border-radius: var(--border-radius);
  border-style: solid;
  border-color: var(--primary-color);
}

.justify-content-space-between{
  justify-content: space-between;
}

.width{
  width: --width;
}

.width-100-percent{
  width: 100%;
}