.wrapper {
    cursor: pointer;
    color: white;
    padding: 0px 10px;
    margin: 0px 5px;
    background-color: #00000000;
}

.wrapper:hover {
    text-decoration: underline;
}