 .sidebar {
     background: rgb(5, 68, 104);
     position: fixed;
     top: 0;
     width: 225px;
     height: 100%;
     padding: 20px 0;
     transition: all 0.5s ease;
     overflow-y: auto;
 }

 .sidebar .profile {
     margin-bottom: 30px;
     text-align: center;
 }

 .sidebar .profile img {
     display: block;
     width: 100px;
     height: 100px;
     border-radius: 50%;
     margin: 0 auto;
 }

 .sidebar .profile h3 {
     color: #ffffff;
     margin: 10px 0 5px;
 }