li {
    display: block;
    padding: 13px 30px;
    border-bottom: 1px solid #10558d;
    color: rgb(241, 237, 237);
    font-size: 16px;
    position: relative;
    cursor: pointer;
}

.icon {
    color: #dee4ec;
    width: 30px;
    display: inline-block;
}

li:hover,
li.active {
    color: #0c7db1;
    text-decoration: underline;
    background: white;
    border-right: 2px solid rgb(5, 68, 104);
}

li:hover .icon,
li.active .icon {
    color: #0c7db1;
}

li:hover:before,
li.active:before {
    display: block;
}