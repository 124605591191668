 .top_navbar {
     background: rgb(7, 105, 185);
     /* height: 50px; */
     display: flex;
     flex-flow: wrap;
     align-items: center;
     padding: 5px 30px;
     justify-content: space-between;
 }

 .hamburger {
     font-size: 28px;
     color: #f4fbff;
 }

 .hamburger:hover {
     color: #a2ecff;
     cursor: pointer;
 }