.html-progress {
    background-color: lightgray;
    width: 100%;
    height: 4px;
}

.progress-value {
    background-color: rgb(5, 68, 104);
    height: 100%;
    animation: grow 1s linear forwards;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

/* animation */
@keyframes grow {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}