.App {
  text-align: center;
}

.section {
  /* width: calc(100% - 225px); */
  /* margin-right: 225px; */
  /* width: 100%; */
  transition: all 0.5s ease;
}

.fixed {
  position: fixed;
  z-index: 1;
  top: 0;
}

.notification-container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 999999
}

.top-right {
  top: 12px;
  right: 12px;
  transition: transform .6s ease-in-out;
  animation: toast-in-right .7s;
}

.bottom-right {
  bottom: 12px;
  right: 12px;
  transition: transform .6s ease-in-out;
  animation: toast-in-right .7s;
}

.top-left {
  top: 12px;
  left: 12px;
  transition: transform .6s ease-in;
  animation: toast-in-left .7s;
}

.bottom-left {
  bottom: 12px;
  left: 12px;
  transition: transform .6s ease-in;
  animation: toast-in-left .7s;
}